@tailwind base;
@tailwind components;
@tailwind utilities;

.popup-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
  }
  .popup-container.open {
    display: block;
  }
